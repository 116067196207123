import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import {Link} from "gatsby";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import {GatsbyImage} from "gatsby-plugin-image";
import {Parallax} from "react-scroll-parallax";
import CoachingPersona from "../components/CoachingPersona";

export const query = graphql`
    {
        allSanityCoaching {
    edges {
      node {
      image{ asset{ gatsbyImageData}}
      credits
        kooperation
        zusatzinfo
        eventinfo
        _rawIntrotext
        personas1 {
           name
           company
          _rawText
          _rawIntrotext
          image {
            asset {
              gatsbyImageData
            }
          }
        }
       
        personas2 {
          name
           company
          _rawText
          _rawIntrotext
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        personas3 {
          name
           company
          _rawText
          _rawIntrotext
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        personas4 {
          name
           company
          _rawText
          _rawIntrotext
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        personas5 {
          name
           company
          _rawText
          _rawIntrotext
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
        }
    `;

const Coaching = ({data}) => {

    const coaching = data.allSanityCoaching.edges[0].node;

    return (
        <Layout newsletter={false} page={"coaching"} backBtn={true}>
            <SEO title={"Coaching"}/>
            <div className="coaching__outer">
                <div className="coaching__inner">
                    <div className="coaching__landing__outer">
                        <div className="coaching__landing__inner">
                            <div className="coaching__image mobile">
                                <GatsbyImage image={coaching.image.asset.gatsbyImageData}></GatsbyImage>
                                <p className="credits__container small">FOTO: {coaching.credits}</p>
                            </div>
                            <Parallax translateY={[-20, 80]} className="left coaching__title desktopblock">
                                <h2 className={"larger"}>Coaching</h2>
                                <p className="demibold">{coaching.eventinfo}</p>
                                <div className="programm__zusatztext">
                                    <p className="small">{coaching.kooperation}</p>
                                    <p className="small">{coaching.zusatzinfo}</p>
                                </div>
                            </Parallax>
                            <div className="left coaching__title mobile">
                                <h2 className={"larger"}>Coaching</h2>
                                <p className="demibold">{coaching.eventinfo}</p>
                                <div className="programm__zusatztext">
                                    <p className="small">{coaching.kooperation}</p>
                                    <p className="small">{coaching.zusatzinfo}</p>
                                </div>
                            </div>
                            <div className="right coaching__introtext">
                                <div className="coaching__image desktopblock">
                                    <GatsbyImage image={coaching.image.asset.gatsbyImageData}></GatsbyImage>
                                    <p className="credits__container small landing">FOTO: {coaching.credits}</p>
                                </div>
                                <h3 className={"paddingright"}>
                                    <MyPortableText value={coaching._rawIntrotext}></MyPortableText>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="coaching__personas__outer">
                        <div className="coaching__personas__inner">
                            <div className="coaching__category">
                                <h2>Finanzierung & Förderungen</h2>
                                <div className="coachingpersona__outer">
                                {coaching.personas1.map((el, index) => (
                                    <CoachingPersona el={el}></CoachingPersona>
                                ))}
                                </div>
                            </div>
                            <div className="coaching__category">
                                <h2>Networking & Lobbying</h2>
                                <div className="coachingpersona__outer">
                                {coaching.personas2.map((el, index) => (
                                    <CoachingPersona el={el}></CoachingPersona>
                                ))}
                                </div>
                            </div>
                            <div className="coaching__category">
                                <h2>Geschäftsmodelle</h2>
                                <div className="coachingpersona__outer">
                                {coaching.personas3.map((el, index) => (
                                    <CoachingPersona el={el}></CoachingPersona>
                                ))}
                                </div>
                            </div>
                            <div className="coaching__category">
                                <h2>Social Media & Website</h2>
                                <div className="coachingpersona__outer">
                                {coaching.personas4.map((el, index) => (
                                    <CoachingPersona el={el}></CoachingPersona>
                                ))}
                                </div>
                            </div>
                            <div className="coaching__category">
                                <h2>PR & Kommunikation</h2>
                                <div className="coachingpersona__outer">
                                {coaching.personas5.map((el, index) =>
                                    <CoachingPersona el={el}></CoachingPersona>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section__outer">
                        <div className="section__inner">
                            <h4 className={"uppercase"}>MDZ</h4>
                            <div className="separator"></div>
                            <div className="coll__outer flexspacebetween">
                                <div className="coll3">
                                    <p className={"smallwidth col3"}>
                                        Mehr Infos zum <span className={"demibold"}>Markt der Zukunft</span> und dem Klimakulturfestival von 7.-9. Oktober findest du <Link to={"/"} className={"underline"}>hier</Link>.
                                    </p>
                                </div>
                                <div className="coll3 coaching desktop">
                                    <Link to={"/#programm"} className="btn__inner light icon--small">
                                        ZUM PROGRAMM
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398" style={{transform : "rotate(-90deg"}}>
                                            <g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707)">
                                                <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
                                                <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Coaching;
