import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText from "./MyPortableText";
import React, {useRef, useState} from "react";

const CoachingPersona = ({el}) => {

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const textRef = useRef();

    return (
        <div className="coaching__category__row reverse">
            <div className="left image desktop">
                <div className="">
                    {el.image ? <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching"}></GatsbyImage> : <></> }
                </div>
            </div>
            <div className="right text">
                <div>
                    <div className="mobile coaching__persona__mobile">
                        {el.image ? <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching"}></GatsbyImage> : <></> }
                        <div className={"name"}>
                            <p className="demibold">{el.name}</p>
                            <p className="small">{el.company}</p>
                        </div>
                    </div>
                    <p className="demibold desktop">{el.name}</p>
                    <p className="small coaching__company desktop">{el.company}</p>
                    <MyPortableText value={el._rawIntrotext}></MyPortableText>
                    <br/>
                    <div className="collapsed" ref={textRef}
                         style={open ? {height: textRef.current.scrollHeight + "px"} : {height: "0px"}}>
                        <MyPortableText value={el._rawText}></MyPortableText>
                        <br/>
                    </div>
                    {el._rawText ?
                        <div className="btn__outer flex mobilemargin">
                            <div className="btn__inner light flexend social" onClick={toggle}>
                                <svg className={open ? "rotated" : ""} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 18.347 18.346">
                                    <g className={"svg-path-dark svg-path"} id="Group_169" data-name="Group 169" transform="translate(-1755.807 -3662.46)">
                                        <path id="Path_274" data-name="Path 274" d="M135.631,11.23l10.144,10.145" transform="translate(1677.016 3560.613) rotate(45)"/>
                                        <path id="Path_275" data-name="Path 275" d="M0,0,10.145,10.144" transform="translate(1772.154 3671.633) rotate(135)"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        : <></>}

                </div>
            </div>
        </div>
    );
}

export default CoachingPersona;
